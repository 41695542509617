import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './locales/en/index'

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    resources: {
      en
    },
    lng: 'en',
    nsSeparator: '::',
    defaultNS: 'common',
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
