import { lazy } from 'react'
import { ROUTE_PATHS } from 'utils/constants/index'
const NewCrashGame = lazy(() => import('pages/NewCrashGame/index'))

const routesList = [
  {
    path: ROUTE_PATHS.crashGame,
    component: NewCrashGame,
    isPrivate: false,
    isHeader: true,
    isFooter: false
  }
]

export default routesList
