import { getTranslation } from 'network/messageTranslationHelpers/index'
import { ErrorMessage } from 'network/messageTranslationHelpers/messages'
import { getAuthToken, signOut } from 'utils/common-services/cookie.service'
import { openErrorToaster, openSuccessToaster } from 'utils/common-services/toaster.service'

export const requestHandler = (request) => {
  if (request.handlerEnabled) {
    const authToken = getAuthToken()
    if (authToken) {
      request.headers.authorization = `Bearer ${authToken}`
    }
  }
  // Loader Logic to add loader
  // if (request.loader) {
  //   store.dispatch(startLoader(request.loader))
  // }
  return request
}

export const responseSuccessHandler = (response) => {
  const { responseType = 'json', successMessage } = response.config || {}

  if (responseType === 'blob') {
    return { file: response.data, headers: response.headers }
  }
  // Loader logic to remove loader
  // if (loader) {
  // store.dispatch(stopLoader(loader))
  // }

  // Open Success Toaster
  successMessage && openSuccessToaster({ message: getTranslation(successMessage) })
  return response.data.data
}

export const errorHandler = (error) => {
  if (error.response.status === 401) {
    // Snackbar UnAuthed
    openErrorToaster({ message: getTranslation(ErrorMessage.unAuthorized) })
    signOut()
    // store.dispatch(stopLoader(error.response.config.loader))
    return Promise.reject(error.response.data.errors)
  }

  openErrorToaster({ message: error.response.data.errors[0].description })
  return Promise.reject(error.response.data.errors)
  //  else if (error.response.status === 500) {
  //   // Snackbar Internal Server Error
  //   openErrorToaster({ message: getTranslation(ErrorMessage.internalServerError) })
  //   // store.dispatch(stopLoader(error.response.config.loader))
  //   return Promise.reject(error.response.data.errors)
  // }
  // Other errors
  // if (error.response.config.loader) {
  // store.dispatch(stopLoader(error.response.config.loader))
  // }

  // Open Error Toaster
  // const apiErrorCode = error.response.data.errors[0].errorCode

  // if (error.response.config.url === 'user/login') {
  //   console.log("CHALA")
  //   <Redirect to='/' />
  // }
}
