import React, { useState, useEffect } from 'react'
import logo from '../../../../src/assets/images/logo.svg'
import twitter from '../../../../src/assets/images/twitter.svg'
import telegram from '../../../../src/assets/images/telegram.svg'
import youtube from '../../../../src/assets/images/youtube.svg'
import './Footer.scss'

// const isMobile = window.matchMedia('(max-width: 1024px)').matches

const Footer = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    // <!-- FOOTER SECTION START -->
    <footer className='footer-section'>
      {
        isMobile
          ? (
            <div className='d-flex align-items-center justify-content-between'>
              <div>
                <ul className='d-flex gap-1 gap-md-3 list-unstyled m-0 p-0 footer-link flex-column flex-md-row'>
                  <li>
                    <a href='#'>
                      Terms & conditions
                    </a>
                  </li>

                  <li>
                    <a href='#'>
                      Privacy policy
                    </a>
                  </li>

                  <li>
                    <p className='copyright'>
                      © All rights reserved
                    </p>
                  </li>
                </ul>
              </div>
              <div>
                <ul className='d-flex gap-3 list-unstyled m-0 p-0'>
                  <li>
                    <a href='#'>
                      <img src={telegram} alt='telegram' />
                    </a>
                  </li>

                  <li>
                    <a href='#'>
                      <img src={twitter} alt='twitter' />
                    </a>
                  </li>

                  <li>
                    <a href='#'>
                      <img src={youtube} alt='youtube' />
                    </a>
                  </li>
                </ul>
              </div>

            </div>
            )
          : (
            <div className='d-flex align-items-center justify-content-between'>
              <div className='d-flex gap-5 align-items-center'>
                <img src={logo} alt='logo' />
                <p className='copyright'>
                  © All rights reserved
                </p>
              </div>
              <div>
                <ul className='d-flex gap-3 list-unstyled m-0 p-0'>
                  <li>
                    <a href='#'>
                      <img src={telegram} alt='telegram' />
                    </a>
                  </li>

                  <li>
                    <a href='#'>
                      <img src={twitter} alt='twitter' />
                    </a>
                  </li>

                  <li>
                    <a href='#'>
                      <img src={youtube} alt='youtube' />
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <ul className='d-flex gap-3 list-unstyled m-0 p-0 footer-link'>
                  <li>
                    <a href='#'>
                      Terms & conditions
                    </a>
                  </li>

                  <li>
                    <a href='#'>
                      Privacy policy
                    </a>
                  </li>

                  <li>
                    <a href='#'>
                      FAQ
                    </a>
                  </li>

                  <li>
                    <a href='#'>
                      info
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            )
      }
    </footer>

  // <!-- FOOTER SECTION END -->

  )
}

export default Footer
