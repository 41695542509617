import axiosInstance, { microServices } from 'network/apis/index'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'

export const getUserInfoService = () => {
  return axiosInstance(METHOD_TYPES.get, 'user/user-detail', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getTransactions = (data, endpoint) => {
  return axiosInstance(METHOD_TYPES.get, endpoint, data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit,
    params: data

  })
}

export const generateServerSeedService = () => {
  return axiosInstance(METHOD_TYPES.post, 'user/generate-server-seed', {}, {
    server: microServices.USER_URL
  })
}
