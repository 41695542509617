export const LANGUAGES = [
  { label: 'English', languageCode: 'en' },
  { label: 'German', languageCode: 'de' },
  { label: 'Spanish', languageCode: 'es' },
  { label: 'French', languageCode: 'fr' },
  { label: 'Portuguese', languageCode: 'pt' },
  { label: 'Russian', languageCode: 'ru' }
]

/* ==========================================================================
  HTTP Method Types
========================================================================== */
export const METHOD_TYPES = {
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
  patch: 'patch'
}

/* ==========================================================================
LocalStorage / Cookie data
========================================================================== */
export const TOKEN = 'authToken'
export const LANGUAGE = 'operator_language'
export const OPERATOR_ID = 'operator_id'
export const ROLE = 'operator_role'

/* ==========================================================================
Operator Roles
========================================================================== */
export const OPERATOR_ROLES = {
  operator: 'OPERATOR',
  operatorUser: 'OPERATOR_USER'
}

/* ==========================================================================
Loader types
========================================================================== */
export const LOADER = {
  BUTTON: 'button',
  CONTENT: 'content',
  TABLE_CELL: 'tableCell'
}

/* ==========================================================================
Loader types
========================================================================== */
export const LOADER_TYPE = {
  SCALE: 'scale',
  PULSE: 'pulse'
}

/* ==========================================================================
  TOASTER / NOTIFICATION Types
========================================================================== */
export const TOASTER_TYPE = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info'
}

/* ==========================================================================
  Loader Types
========================================================================== */
export const LOADER_HANDLER_TYPES = {
  page: 'pageLoader',
  submit: 'submitButtonLoader',
  table: 'tableLoader'
}

/* ==========================================================================
  All the navigation route Paths
========================================================================== */
export const ROUTE_PATHS = {
  // ----single routes------------
  home: '/',
  crashGame: '/crash-game'
}

/* ==========================================================================
  Game Ids
========================================================================== */
export const DEFAULT_GAME_ID = {
  CRASH: 1
}

/* ==========================================================================
  Extra Constants Variables
========================================================================== */
export const PAGINATION_LIMIT = 20
export const MIN_WITHDRAW_AMOUNT = 0.003
export const WITHDRAW_FEES = 0.000006
export const DEFAULT_PAGE_CALLS = 20
export const DEFAULT_TOP_BET_TYPE_LIMIT = 20
export const DEFAULT_MY_BETS_LIMIT = 20
export const DEFAULT_PRECISION_MONEY = 2
export const DEFAULT_AUTO_BET_COUNTER = 3
export const DEFAULT_BET_AMOUNT_SC = 1
export const DEFAULT_BET_AMOUNT_GC = 2500
export const TAB_SELECTION = Object.freeze({
  placed: Symbol('placed'),
  previous: Symbol('previous')
})
export const MIN_PAYOUT = {
  CRASH: 1.1
}

export const APPEND_TYPES = {
  ROUND_STOPPED: 'ROUND_STOPPED',
  ESCAPE: 'ESCAPE'
}

/* ==========================================================================
  Game Result
========================================================================== */
export const BET_RESULT = {
  WON: 'won',
  LOST: 'lost',
  CANCELLED: 'cancelled'
}

export const COIN_CURRENCY_ID = {
  USD: '1',
  SC: '2'
}

export const COIN_CURRENCY_CODES = {
  USD: 'USD',
  SC: 'SC'
}

/* ==========================================================================
  Wallet emit types
========================================================================== */
export const WALLET_EMIT_TYPES = {
  CREDIT: 'credit',
  DEBIT: 'debit',
  RESOLVE: 'resolve',
  DEFAULT: 'default'
}

export const PROVABLE_FAIR_TEXT = '"Provably fair" is a term that refers to a system or algorithm used in online gambling or gaming that can be mathematically verified to ensure that the results are fair and not manipulated in any way. It is a way for players to know that the game or gambling platform they are using is legitimate and not rigged against them.'

export const TOP_BET_TYPE = {
  BIGGEST_WIN: 'BIGGEST_WIN',
  HUGE_WIN: 'HUGE_WIN',
  MULTIPLIER: 'MULTIPLIER',
  GOLD_COINS: 'GC',
  SWEEPSTAKES_COINS: 'SC'
}

export const CRASH_BET_LISTING_TYPE_TABS = {
  ALL_BETS: 'ALL_BETS',
  MY_BETS: 'MY_BETS',
  TOP_BETS: 'TOP_BETS'
}
