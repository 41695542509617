import common from './common.json'
import crashGame from './crashGame.json'
import diceGame from './diceGame.json'
import betHistory from './betHistory.json'
import transaction from './transaction.json'

export default {
  common,
  crashGame,
  betHistory,
  transaction,
  diceGame
}
