import { combineReducers } from '@reduxjs/toolkit'
import gameSetting from './slices/gameSettings.slice'
import crashGame from './slices/crashGame.slice'
import authSlice from './slices/auth.slice'

const rootReducer = combineReducers({
  gameSetting,
  crashGame,
  auth: authSlice
})

export default rootReducer
