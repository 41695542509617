import { TOKEN } from 'utils/constants/index'

/* ==========================================================================
  Auth Token
========================================================================== */
export const getAuthToken = () => {
  return window.sessionStorage.getItem(TOKEN)
}

export const setAuthToken = (authAccessToken) => {
  window.sessionStorage.setItem(TOKEN, authAccessToken)
}

export const removeAuthToken = () => {
  window.sessionStorage.removeItem(TOKEN)
}

/* ==========================================================================
  SignIn Action
========================================================================== */
export const signIn = ({ token }) => {
  setAuthToken(token)
}

/* ==========================================================================
  SignOut Action
========================================================================== */
export const signOut = () => {
  removeAuthToken()
}
