import Routes from 'routes/index'
import store from 'redux-store/store'
import { Provider } from 'react-redux'
import React, { Suspense } from 'react'
import Loader from 'components/ui-kit/Loader/index'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import ErrorBoundary from 'components/ui-kit/ErrorBoundary/index'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App () {
  return (
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <ErrorBoundary>
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
            style={{ zIndex: 99999 }}
          />
          <Routes />
        </ErrorBoundary>
      </Provider>
    </Suspense>
  )
}

export default App
