import axiosInstance, { microServices } from 'network/apis/index'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'

export const getAllCurrencyService = () => {
  return axiosInstance(METHOD_TYPES.get, 'system/get-all-currency', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}
export const getGameSettingService = () => {
  return axiosInstance(METHOD_TYPES.get, 'system/get-game-settings', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getGameDetailsService = () => {
  return axiosInstance(METHOD_TYPES.get, 'system/get-game-settings', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}
