import React, { lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from './HOC/PrivateRoutes/index'
import PublicRoutes from './HOC/PublicRoutes/index'
import { getAuthToken } from 'utils/common-services/cookie.service'
import routesList from './routesList'
const NotFoundComponent = lazy(() => import('components/layout/404/index'))

const RouteRenderer = (route) => {
  const { component: Component, child: Child, isHeader, isFooter } = route
  const isAuthenticated = getAuthToken()

  return (
    route?.isPrivate
      ? <PrivateRoute isAuthenticated={isAuthenticated} Component={Component} child={Child} isHeader={isHeader} isFooter={isFooter} />
      : <PublicRoutes isHeader={isHeader} Component={Component} child={Child} isFooter={isFooter} />

  )
}
const CustomRoutes = () => (
  <>
    <Switch>
      {
        routesList.map((route) => (
          <Route path={route.path} key={route.path} exact render={() => RouteRenderer(route)} />
        ))
      }
      <Route component={() => <NotFoundComponent />} />
    </Switch>
  </>
)

export default CustomRoutes
