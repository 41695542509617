import React from 'react'
import { Redirect } from 'react-router-dom'
import Footer from 'components/layout/Footer/index'
import NewHeader from 'components/layout/NewHeader/index'

const PrivateRoute = ({ Component, isAuthenticated, child, isHeader, isFooter }) => {
  return isAuthenticated
    ? (
      <>
        {isHeader ? <NewHeader /> : <></>}
        <Component child={child} />
        {isFooter ? <Footer /> : <></>}
      </>
      )
    : (
      <Redirect to={{ pathname: '/' }} />
      )
}

export default PrivateRoute
