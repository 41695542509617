import React, { useState, useEffect, useRef } from 'react'
import './Header.scss'
import * as yup from 'yup'
// import Button from 'components/ui-kit/Button/index'
// import { AnimationIcon, CrossIcon, MusicIcon, RightArrowIcon, SoundIcon, TogglerIcon,  SettingsIcon, UserIcon } from 'assets/icons/index'
// import SwitchButton from 'components/ui-kit/SwitchButton/index'
// import Image from 'components/ui-kit/Image/index'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserInformation } from 'redux-store/thunk/user.thunk'
// import { getPrecision, numberWithCommas } from 'utils/common-functions/index'
import useGameSettingsUpdateSocketNameSpace from 'socket-resources/hooks/useGameSettingsUpdateSocketNameSpace'
import { getAllCurrency, getGameSetting } from 'redux-store/thunk/gameSettings.thunk'
// import { signOut } from 'utils/common-services/cookie.service'
import { updateGameSetting } from 'redux-store/redux/slices/gameSettings.slice'
import { DEFAULT_GAME_ID, ROUTE_PATHS } from 'utils/constants/index'
import useOperatorAuthentication from 'hooks/useOperatorAuthentication'

const NewHeader = () => {
  const dispatch = useDispatch()
  useOperatorAuthentication()
  const {
    // userInfo,
    // userDetails,
    isLoggedIn
    // showName
  } = useSelector((state) => state.auth)
  // const {
  // gameMusic,
  // gameSound
  // } = useSelector((state) => state.gameSetting)
  const listenGameSettingsUpdate = useGameSettingsUpdateSocketNameSpace()
  // const [isOpenHistoryModal, setIsOpenHistoryModal] = useState(false)
  // const [isOpenProvablyFair, setIsOpenProvablyFair] = useState(false)
  // const [isOpenGameLimits, setIsOpenGameLimits] = useState(false)
  // const [isOpenHowToPlay, setIsOpenHowToPlay] = useState(false)
  // const [showGameInfoModal, setShowGameInfoModal] = useState(false)
  // const [currentOffset, setCurrentOffset] = useState(0)
  const [gameId, setGameId] = useState('')

  // const displayName = useMemo(() => {
  //   return userDetails?.firstName || userDetails?.lastName || 'Player'
  // }, [userDetails])

  const musicRef = useRef(null)
  const buttonClickSoundRef = useRef(null)

  useEffect(() => {
    const gamePath = window.location.pathname
    let id = ''
    switch (gamePath) {
      case ROUTE_PATHS.crashGame:
        id = DEFAULT_GAME_ID.CRASH
        break
      default:
        id = ''
    }
    setGameId(id)
  }, [])

  useEffect(() => {
    musicRef.current = new Audio()
    musicRef.current.src = '/assets/music/game-music-new.mp3'
    musicRef.current.loop = true

    buttonClickSoundRef.current = new Audio()
    buttonClickSoundRef.current.src = '/assets/music/button-click.wav'

    dispatch(getAllCurrency())
    dispatch(getGameSetting())
    window.addEventListener('beforeunload', () => {
      // signOut()
    })

    const clearGameSettingsUpdate = listenGameSettingsUpdate(e => {
      // console.log(e.data)
      handleGameSettingsUpdate(e.data)
    })

    return () => {
      musicRef.current?.pause()
      buttonClickSoundRef.current?.pause()
      clearGameSettingsUpdate()
    }
  }, [])

  useEffect(() => {
    if (gameId === DEFAULT_GAME_ID.CRASH && !musicRef.current.isPlaying) {
      musicRef.current = new Audio()
      musicRef.current.src = '/assets/music/game-music-new.mp3'
      musicRef.current.loop = true
      musicRef.current.volume = 0.90
    }
  }, [gameId])

  // const playClickSound = () => {
  //   if (buttonClickSoundRef.current && gameSound) {
  //     buttonClickSoundRef.current?.play()
  //   }
  // }

  const systemGameDataSchemas = yup.array().of(yup.object().shape({
    id: yup.string().required(),
    gameId: yup.number().required(),
    maxBet: yup.object().shape({
      GC: yup.string(),
      SC: yup.string()
    }),
    minBet: yup.object().shape({
      GC: yup.string(),
      SC: yup.string()
    }),
    maxProfit: yup.object().shape({
      GC: yup.string(),
      SC: yup.string()
    }),
    houseEdge: yup.number().required(),
    minOdds: yup.number().required(),
    maxOdds: yup.number().required(),
    minAutoRate: yup.number().required(),
    createdAt: yup.string().required(),
    updatedAt: yup.string().required()
  })).min(1)

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchUserInformation())
    }
  }, [isLoggedIn])

  const handleGameSettingsUpdate = async (data) => {
    if (data) {
      const isValid = await systemGameDataSchemas.isValid(data)
      if (isValid) {
        dispatch(updateGameSetting(data))
      }
    }
  }

  // const handleOpen = () => {
  //   playClickSound()
  //   dispatch(setChooseCharacterOpen(true))
  // }

  // const closeDropdown = () => {
  //   const dropdownButton = document.getElementById('dropdownMenuButton1')
  //   if (dropdownButton) {
  //     dropdownButton.click() // Simulate a click on the dropdown button to close it
  //   }
  // }
  // const openExternalLink = (url) => {
  //   window.parent.location = url
  // }
  // const handleSwitch = () => {
  //   openErrorToaster({ message: 'Switch Currency in DDD Lobby!' })
  // }

  // const handleGameMusic = () => {
  //   if (musicRef?.current) {
  //     if (gameMusic) {
  //       !musicRef?.current?.paused && musicRef?.current?.pause()
  //     } else {
  //       musicRef?.current?.paused && musicRef?.current?.play()
  //     }
  //   }
  //   dispatch(setGameMusic())
  // }

  // const handleGameSound = () => {
  //   if (gameSound) {
  //     VolumeController(0)
  //   } else {
  //     VolumeController(1)
  //   }
  //   dispatch(setGameSound())
  // }

  return (
    <></>
  )
}

export default NewHeader
