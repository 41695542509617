import axiosInstance, { microServices } from 'network/apis/index'
import { SuccessMessage } from 'network/messageTranslationHelpers/messages'
import { METHOD_TYPES } from 'utils/constants/index'

export const placeBetCrashGameService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'crash-game/place-bet-crash-game', data, {
    server: microServices.USER_URL
    // successMessage: SuccessMessage.betPlacedSuccessfully
  })
}
export const escapeCrashBetService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'crash-game/player-escape-crash-game', data, {
    server: microServices.USER_URL
  })
}

export const cancelCrashBetService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'crash-game/cancel-bet-crash-game', data, {
    server: microServices.USER_URL,
    successMessage: SuccessMessage.betCancelledSuccessfully
  })
}
export const getAllRoundHistoryService = () => {
  return axiosInstance(METHOD_TYPES.get, '/crash-game/get-crash-game-history', {
    server: microServices.USER_URL
  })
}

export const getMyBetsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/crash-game/my-bets', {}, {
    params,
    server: microServices.USER_URL
  })
}
export const getPreviousRoundBetsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, 'crash-game/previous-round-placed-bets', {}, {
    params,
    server: microServices.USER_URL
  })
}

export const getTopBetsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/crash-game/top-bets', {}, {
    params,
    server: microServices.USER_URL
  })
}

export const verifyProvableFairCrashService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'crash-game/check-provable-fair', data, {
    server: microServices.USER_URL
  })
}

export const getAllBetsByRoundIdCrashService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/crash-game/all-placed-bets', {}, {
    params,
    server: microServices.USER_URL
  })
}
